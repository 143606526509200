import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import Button from "../components/Button";
import { ApplicationContext } from "../providers/ApplicationProvider";
const ApplicationSubmitted = () => {
    const { lingo } = useContext(ApplicationContext);
    const nodeRef = useRef(null);
    const [contentActive, setContentActive] = useState(false) 
    const active = true;
    const image = '';
    const background = 'blur';
    const navigate = useNavigate();

    const { innerHeight, innerWidth} = window;

    const calclulateBubbleSize = () => {
        
        let pixelValue = innerHeight + 100;
        if(innerHeight > innerWidth){
            pixelValue = innerHeight * 0.95;
        }
        return pixelValue;
    }
    
    useEffect(() => {
        setContentActive(active)
    }, [active])

    return (
        <>
            <CSSTransition nodeRef={nodeRef} in={active} timeout={200} classNames="bubble-modal" unmountOnExit>
                <div ref={nodeRef} className={"bubble-modal " + background} 
                style={{
                    backgroundImage: background ? 'url(' + process.env.PUBLIC_URL + '/gfx/blurbg.png)' : 'none'
                }}>
                    <CSSTransition nodeRef={nodeRef} in={contentActive} timeout={400} classNames="bubble-modal-content" >
                        <div ref={nodeRef} className="bubble-modal-content bg-white" style={{backgroundImage: image ? `url(${image})` : 'none',minWidth: calclulateBubbleSize(), minHeight: calclulateBubbleSize()}}>
                            <div className="d-flex flex-column h-100 justify-content-between " style={{maxWidth: '60vw'}}>
                                <div className="text-center" style={{
                                    maxWidth: '60%', 
                                    margin: '0 auto'
                                }}>
                                    <h5>{lingo.application_submitted_title}</h5>
                                    <FontAwesomeIcon className="py-4 text-green" size="3x" icon={['far', 'check']} />
                                    <div className="mb-3">
                                        <h2>{lingo.application_submitted_thankyou}</h2>
                                        <p>{lingo.application_submitted_text}</p>
                                    </div>
                                    <div className="row justify-content-center mt-5">
                                        <div className="col-auto">
                                            <Button
                                                title={lingo.application_submitted_to_portal}
                                                type="primary"
                                                onClick={() => {
                                                    navigate('/din-side');
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CSSTransition>
                </div>
            </CSSTransition>
        </>
     );
}
 
export default ApplicationSubmitted;